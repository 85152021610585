export const ReportModuleCode = {
	General: 'general',
	Advertising: 'advertising',
	AdvGeneral: 'adv-general',
	AdvCompare: 'adv-compare',
	Directions: 'directions',
	Zagorodka: 'zagorodka',
	Novostroyki: 'novostroyki',
	Vtorichka: 'vtorichka',
	Ipoteka: 'ipoteka',
	Commercial: 'commercial',
	Hr: 'hr',
	Aggregators: 'aggregators',
	AgSummary: 'ag-summary',
	AgObjects: 'ag-objects',
	AgRieltors: 'ag-rieltors',
	Smm: 'smm',
	Clients: 'clients',
	Etagicom: 'etagicom',
	SavedFilters: 'saved-filters',
  EtagiInsight:'floors-insight',
  MatrixMonth:'matrix-month',
  ChoosingCity:'choosing-city',
  SelectingIndicator:'selecting-indicator',
} as const;

export type ReportModuleCode =
	(typeof ReportModuleCode)[keyof typeof ReportModuleCode];
